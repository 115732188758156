<template>
    <div id="app">

        <div class="header">
            <van-nav-bar title="商家收银台" left-arrow @click-left="goBack">
                <template #left>
                    <van-icon name="arrow-left" size="20" color="#000000"/>
                </template>
            </van-nav-bar>
        </div>
        <div class="content">
            <div class="money">
                <span class="money_symbol">￥</span>{{pay_params.amount}}
            </div>
            <div class="pay-type">
                <div class="pay-type-title">选择支付方式</div>
                <div>
                    <van-radio-group v-model="radio">
                        <van-cell-group :border="false">
                            <van-divider></van-divider>
                            <van-cell :border="false" class="pay-type-item" title="单选框 1" clickable
                                      @click="radio = '1'">
                                <template #title>
                                    <div style="display: flex;align-items: center;">
                                        <van-icon :name="pay_icon_wechat" size="30"></van-icon>
                                        <div style="margin-left: 16px;">
                                            <div class="pay-type-name">微信支付</div>
                                        </div>
                                    </div>
                                </template>
                                <template #right-icon>
                                    <van-radio name="1" checked-color="#ef2505"/>
                                </template>
                            </van-cell>
                            <van-divider></van-divider>
                            <van-cell :border="false" class="pay-type-item" title="单选框 1" clickable
                                      @click="radio = '2'">
                                <template #title>
                                    <div style="display: flex;align-items: center;">
                                        <van-icon :name="pay_icon_alipay" size="30"></van-icon>
                                        <div style="margin-left: 16px;">
                                            <div class="pay-type-name">支付宝支付</div>
                                        </div>
                                    </div>
                                </template>
                                <template #right-icon>
                                    <van-radio name="2" checked-color="#ef2505"/>
                                </template>
                            </van-cell>
                            <div class="divider"></div>
                            <van-cell :border="false" class="pay-type-item" title="单选框 2" clickable
                                      @click="radio = '3'">
                                <template #title>
                                    <div style="display: flex;align-items: center;">
                                        <van-icon :name="pay_icon_wallet" size="30"></van-icon>
                                        <div style="margin-left: 16px;">
                                            <div style="display: flex;align-items: center;">
                                                <div class="pay-type-name">芝富通</div>
                                                <van-tag type="danger" style="font-size: 10px;margin-left: 4px;">推荐
                                                </van-tag>
                                            </div>
<!--                                            <div class="pay-type-desc">随机立减，每单最高可减50元</div>-->
                                        </div>
                                    </div>
                                </template>
                                <template #right-icon>
                                    <van-radio name="3" checked-color="#ef2505"/>
                                    <!--<div style="color: #ef2505;display: flex;align-items: center;border: 1px solid #ef2505;border-radius: 90px;height: 24px;padding: 0px 4px;margin-top: 10px;">
                                        <div style="font-size: 12px;">立即开通</div>
                                        <van-icon name="arrow" ></van-icon>
                                    </div>-->
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
            </div>
        </div>
        <div class="footer">
            <van-button type="primary" color="#ef2505" style="width:100%;" block @click="payOrder" v-if="radio == ''">支付</van-button>
            <van-button type="primary" color="#ef2505" style="width:100%;" block @click="payOrder" v-if="radio == '1'">微信支付￥{{pay_params.amount}}</van-button>
            <van-button type="primary" color="#ef2505" style="width:100%;" block @click="payOrder" v-if="radio == '2'">支付宝支付￥{{pay_params.amount}}</van-button>
            <van-button type="primary" color="#ef2505" style="width:100%;" block @click="payOrder" v-if="radio == '3'">芝富通支付￥{{pay_params.amount}}</van-button>
        </div>
    </div>
</template>
<script>
    import {
        NavBar,
        RadioGroup,
        Radio,
        CellGroup,
        Cell,
        Icon,
        Tag,
        Button,
        Divider,
        Toast
    } from 'vant';

    export default {
        name: "index",
        components: {
            [NavBar.name]: NavBar,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [CellGroup.name]: CellGroup,
            [Cell.name]: Cell,
            [Icon.name]: Icon,
            [Tag.name]: Tag,
            [Button.name]: Button,
            [Divider.name]: Divider,
            [Toast.name]: Toast,
        },
        data() {
            return {
                pay_icon_wallet: require("../../assets/pay_icon_wallet.png"),
                pay_icon_alipay: require("../../assets/pay_icon_alipay.png"),
                pay_icon_wechat: require("../../assets/pay_icon_wechat.png"),
                radio: '',
                pay_params:{}
            }
        },
        mounted() {
            console.log(this.$route.params)
            if(this.$route.params.amount === undefined || this.$route.params.amount === ''){
                this.$router.replace('home')
            }
            this.pay_params = this.$route.params
            window.addEventListener("unload", (e) => this.unloadHandler(e));

        },
        destroyed() {
            console.log('销毁')
            window.removeEventListener("unload", (e) => this.unloadHandler(e));
        },
        methods: {
            // 页面关闭
            async unloadHandler() {
                // 退出登录
                alert('111')
                await this.closeListener();
            },
            closeListener(){
                fetch('http://116.205.157.214:8127/sys/yl/bank/ylAreaList?shortNo=2102').then((res)=>{
                    console.log(res)
                })
            },
            payOrder() {
                // Toast('测试')
                //let rand = Math.random()>0.5?1:0
                //this.$router.push({name:'result',params:{state:rand}})
                if (this.radio == '') {
                    Toast('请选择支付方式')
                } else if (this.radio == '1') {
                    this.$request.post('sys/wxmp/getMaAppUrlLink',this.pay_params
                    ).then((res) => {
                        // eslint-disable-next-line no-debugger
                        if(parseInt(res.code) === 200){
                            // location.href = res.data.pay_url
                            location.replace(res.data.urlLink)
                        }
                    })
                } else if (this.radio == '2') {
                    this.$request.post('sys/order/orderZFBH5',
                        this.pay_params
                    ).then((res) => {
                        // eslint-disable-next-line no-debugger
                        if(parseInt(res.code) === 200){
                            // location.href = res.data.pay_url
                            location.replace(res.data.pay_url)
                        }
                    })
                } else if (this.radio == '3') {
                    // Toast('未配置')
                    this.$router.push('download')
                }
            },
            goBack() {
                this.$router.back()
            }
        }
    }
</script>
<style scoped lang="less">

    #app {
        color: #333;
        background-color: #ffffff;
        min-height: 100vh;
    }

    .money {
        padding: 20px 0px;
        padding-top: 40px;
        text-align: center;
        color: #ef2505;
        font-size: 30px;
        background: #FFFFFF;
    }

    .money_symbol {
        font-size: 20px;
    }

    .pay-type {
        background: #FFFFFF;
        &-title{
            color: #888888;
            font-size: .8rem;
            margin: 10px 16px;
        }
        &-name {
            font-size: 16px;
        }

        &-desc {
            font-size: 10px;
            color: #ef2505;
        }

        &-item {
            padding: 20px 16px;
        }
    }


    .divider {
        height: 12px;
        background: #f0f0f0;
    }

    .footer {
        position: fixed;
        bottom: 0px;
        width: calc(100% - 40px);
        padding: 20px;
    }

    .van-divider {
        margin: 0px;
    }
</style>
